<template>
  <div class="reservation-registered">
    <base-loading :waitFlg="apiWaitFlg" />
    <div class="reservation-registered-area" v-if="!apiWaitFlg">
      <!-- 初回予約 登録/申請 に失敗 > 認証済みの飼主だった場合 -->
      <div class="error" v-if="alreadyOpenUrlFlg">
        <base-page-title class="title">{{
          $t('reservationRegistered.titleVerified')
        }}</base-page-title>
      </div>

      <!-- 初回予約 登録/申請 に失敗 > 予約の来院目的が削除済みだった場合 -->
      <div class="error" v-else-if="noReservationPurpose">
        <base-page-title class="title">{{
          $t('reservationRegistered.titleRegistrationError')
        }}</base-page-title>
        <div class="message" data-test="error-p1">
          {{ $t('reservationRegistered.errorReservationPurposeDeleted') }}
        </div>
      </div>

      <!--  初回予約 登録/申請 に失敗 > 予約条件を満たせなかった場合 -->
      <div class="error" v-else-if="notAvailableReservationFlg">
        <base-page-title class="title">{{
          $t('reservationRegistered.titleRegistrationError')
        }}</base-page-title>
        <div class="message" data-test="error-p1">
          {{ $t('reservationRegistered.errorDateTimeUnavailable') }}
        </div>
      </div>

      <!--  初回予約 登録/申請 に失敗 > バージョン不具合 -->
      <div class="error" v-else-if="versionUploadingFlg">
        <base-page-title class="title" data-test="title ja">{{
          '現在この予約システムをアップデートしております。暫くお待ちになってから再度お試し下さい。'
        }}</base-page-title>
        <base-page-title class="title" data-test="title en">{{
          'Currently under maintenance. Please wait a moment and try again.'
        }}</base-page-title>
      </div>

      <!--  初回予約 登録/申請 に失敗 > その他のエラー/通信エラー -->
      <div class="error" v-else-if="serverErrorFlg">
        <base-page-title class="title" data-test="title ja">{{
          '間違ったアドレスにアクセスしました。正しいアドレスをご確認ください。'
        }}</base-page-title>
        <base-page-title class="title" data-test="title en">{{
          'You have accessed the wrong address. Please check the correct address.'
        }}</base-page-title>
      </div>

      <!-- 初回予約登録 or 予約登録変更に成功した場合 -->
      <div class="normal" v-else-if="reservationSetting.autoConfirmFlg === 1">
        <div class="page-title">
          <base-page-title>{{
            $t('reservationRegistered.pageTitle')
          }}</base-page-title>
        </div>
        <div class="reservation-progress-bar">
          <progress-bar v-bind="progressBarData" />
        </div>
        <div class="check-mark"><base-check-mark /></div>
        <div class="message">
          <div class="message-p1" data-test="message-p1">
            {{ $t('reservationRegistered.messageReservationComplete') }}
          </div>
          <div class="message-p2" data-test="message-p2">
            <span>{{ $t('reservationRegistered.messageSentMail') }}</span
            ><br /><span v-if="clinic.contactTelShowFlg === 1">{{
              $t('common.textIfNoEmail')
            }}</span>
          </div>
          <div
            class="message-p3"
            v-if="clinic.contactTelShowFlg === 1"
            data-test="message-p3"
          >
            {{ displayClinicName }}{{ clinic.contactTel }}
          </div>
          <div class="message-p4" data-test="message-p4">
            {{ $t('reservationRegistered.messageStatus') }}
          </div>
        </div>
      </div>

      <!-- 初回予約申請 or 予約申請 に成功した場合 -->
      <div class="normal" v-else>
        <div class="page-title">
          <base-page-title>{{
            $t('reservationRegistered.pageTitleRequest')
          }}</base-page-title>
        </div>
        <div class="reservation-progress-bar">
          <progress-bar v-bind="progressBarData" />
        </div>
        <div class="check-mark"><base-check-mark /></div>
        <div class="message">
          <div class="message-p1" data-test="message-p1">
            {{ $t('reservationRegistered.messageReservationRequestComplete') }}
          </div>
          <div class="message-p2" data-test="message-p2">
            <span>{{
              $t('reservationRegistered.messageReservationIncomplete')
            }}</span
            ><br /><span v-if="clinic.contactTelShowFlg === 1">{{
              $t('reservationRegistered.messageContact')
            }}</span>
          </div>
          <div
            class="message-p3"
            v-if="clinic.contactTelShowFlg === 1"
            data-test="message-p3"
          >
            {{ displayClinicName }}{{ clinic.contactTel }}
          </div>
          <div class="message-p4" data-test="message-p4">
            {{ $t('reservationRegistered.messageStatusRequest') }}
          </div>
        </div>
      </div>

      <!-- ボタン ※レスポンスエラーの場合は非表示 -->
      <div class="menu-button">
        <base-decision-button
          v-if="!serverErrorFlg && !versionUploadingFlg"
          @click="pushToPage"
          >{{ $t(buttonText) }}</base-decision-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import BaseCheckMark from '@/components/parts/atoms/BaseCheckMark.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseLoading from '@/components/parts/atoms/BaseLoading.vue'
import { mapGetters } from 'vuex'
import PreventBack from '@/components/mixins/PreventBack'
import axios from 'axios'
import { getApiUrl } from '@/utils/library'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'
import { localizeValue } from '@/utils/reservation_format'

export default {
  name: 'ReservationRegistered',

  components: {
    BasePageTitle,
    ProgressBar,
    BaseCheckMark,
    BaseDecisionButton,
    BaseLoading
  },

  mixins: [PreventBack],

  data() {
    return {
      apiWaitFlg: true,
      alreadyOpenUrlFlg: false,
      versionUploadingFlg: false,
      serverErrorFlg: false,
      notAvailableReservationFlg: false,
      noReservationPurpose: false,
      progressBarData: {}
    }
  },

  computed: {
    ...mapGetters({
      scenario: 'auth/scenario',
      clinic: 'clinic/getClinic',
      reservationSetting: 'reservationSetting/getReservationSetting'
    }),
    displayClinicName() {
      const { name, nameEnglish } = this.clinic
      const clinicName = localizeValue(name, nameEnglish, this.$i18n.locale)
      const colon = this.$i18n.locale === 'en' && nameEnglish ? ': ' : '：'
      return clinicName + colon
    },
    buttonText() {
      return this.alreadyOpenUrlFlg
        ? 'common.buttonToLogin'
        : 'common.buttonMenu'
    }
  },

  created() {
    // 初回予約時、メールリンクを経由するのでシナリオが空になる。
    this.scenario === '' ? this.createReservation() : (this.apiWaitFlg = false)
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 6)
  },

  methods: {
    async createReservation() {
      try {
        const axiosObject = axios.create({
          baseURL: getApiUrl(),
          headers: {
            Authorization: 'Bearer ' + this.$route.query.key,
            'Content-Type': 'application/json',
            'Net-Version': process.env.VERSION
          }
        })
        const res = await axiosObject.post('/reservations/first')
        if (res.data && !res.data.errorMessage) {
          this.setStore(res.data)
          this.setLanguage(res.data.reservationSetting, res.data.owner)
        } else if (res.data.errorMessage === 'already registered owner') {
          this.$store.dispatch(
            'reservationSetting/addReservationSetting',
            res.data.reservationSetting
          )
          this.alreadyOpenUrlFlg = true
        } else if (res.data.errorMessage === 'no reservationPurpose') {
          this.setStore(res.data)
          this.setLanguage(res.data.reservationSetting, res.data.owner)
          this.noReservationPurpose = true
        } else if (res.data.errorMessage === 'not available reservation') {
          this.setStore(res.data)
          this.setLanguage(res.data.reservationSetting, res.data.owner)
          this.notAvailableReservationFlg = true
        }
        sessionStorage.setItem('encryptedClinicId', res.data.clinicIdToken)
      } catch (e) {
        if (
          e.response?.status === 400 &&
          e.response?.data.extra === 'not the same as required version'
        ) {
          this.versionUploadingFlg = true
        } else {
          this.serverErrorFlg = true
        }
      }
      this.apiWaitFlg = false
    },
    setStore(data) {
      this.$store.dispatch('auth/authLogin', data.token)
      this.$store.dispatch('owner/addOwner', data.owner)
      this.$store.dispatch('patient/addPatients', data.patients)
      if (data.reservationRequests) {
        this.$store.dispatch(
          'reservationRequests/setData',
          data.reservationRequests
        )
      }
      this.$store.dispatch('clinic/addClinic', data.clinic)
      this.$store.dispatch(
        'reservationSetting/addReservationSetting',
        data.reservationSetting
      )
      this.$store.dispatch(
        'reservationPurpose/addReservationPurposes',
        data.reservationPurposes
      )
      this.$store.dispatch('species/addSpecies', data.species)
      this.$store.dispatch('master/setData', data.master)
    },
    pushToPage() {
      this.alreadyOpenUrlFlg
        ? this.$router.push({
            path: '/login-top',
            query: { clinic: sessionStorage.getItem('encryptedClinicId') }
          })
        : this.$router.push({ name: 'ReservationMenu' })
    },
    setLanguage(reservationSetting, owner) {
      this.$i18n.locale =
        reservationSetting.englishOptionFlg && owner.englishFlg ? 'en' : 'ja'
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-registered {
  white-space: pre-line;
  > .reservation-registered-area {
    > .error {
      padding: 0 20px;
      > .title {
        margin-top: 70px;
      }
      > .message {
        margin-top: 40px;
      }
    }
    > .normal {
      > .page-title {
        margin-top: 70px;
      }
      > .reservation-progress-bar {
        margin: 28px 0 40px;
      }
      > .message {
        @include font-size(18);
        font-weight: bold;
        color: $plus_black;
        line-height: 1.5;
        > .message-p1 {
          margin-top: 47px;
        }
        > .message-p2 {
          margin-top: 100px;
        }
        > .message-p3 {
          margin-top: 21px;
        }
        > .message-p4 {
          margin-top: 47px;
        }
      }
    }
    > .menu-button {
      margin: 70px auto;
    }
  }
}
@media (max-width: $global-media-width) {
  .reservation-registered {
    > .reservation-registered-area {
      > .normal {
        > .message {
          width: 410px;
          margin: 0 auto;
          > .message-p2,
          .message-p4 {
            text-align: left;
            > br {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $global-container) {
  .reservation-registered {
    > .reservation-registered-area {
      > .normal {
        > .message {
          width: 64%;
          > .message-p2 {
            margin-top: 67px;
          }
        }
      }
    }
  }
}
</style>
